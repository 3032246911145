<template>
	<div
		:id="id"
		v-qa="'builder-gridelement-gridembed'"
		class="grid-embed"
		:style="computedStyles"
	>
		<iframe
			v-if="shouldRender"
			ref="gridEmbedIframeRef"
			class="grid-embed__iframe"
			:srcdoc="srcdoc"
			title="custom code element"
			@load="observeGridEmbed"
		/>
	</div>
</template>

<script setup lang="ts">
import {
	computed,
	ref,
} from 'vue';

type Props = {
	srcdoc: string,
	shouldRender: boolean,
	height: number | null,
	id: string,
}

const props = withDefaults(defineProps<Props>(), {
	srcdoc: '',
	shouldRender: false,
	height: null,
});

const emit = defineEmits(['iframe-height-updated']);

const observedHeight = ref<number>(0);
const gridEmbedIframeRef = ref<HTMLIFrameElement>();

const embedHeight = computed(() => {
	if (props.height && (observedHeight.value > props.height)) {
		return observedHeight.value;
	}

	return props.height || observedHeight.value;
});

const computedStyles = computed(() => ({
	'--height': `${embedHeight.value}px`,
}));

const observeGridEmbed = () => {
	const iframeHtml = gridEmbedIframeRef.value?.contentWindow?.document.querySelector('html') as HTMLElement;

	const observerResizer = new ResizeObserver(([{ contentRect }]) => {
		requestAnimationFrame(() => {
			const { height } = contentRect;

			if (height !== observedHeight.value) {
				observedHeight.value = height;
				emit('iframe-height-updated', observedHeight.value);
			}
		});
	});

	observerResizer.observe(iframeHtml);
};
</script>

<style lang="scss">
.grid-embed {
	width: 100%;
	height: auto;

	&__iframe {
		width: 100%;
		height: var(--height);
		overflow: hidden;
		border: none;
	}
}
</style>
