import {
	BOOKING_DURATION_UNIT_HOURS,
	SITE_PRODUCT_SELECTION,
	SITE_PRODUCT_SELECTION_TYPE_LOWEST,
} from '@zyro-inc/site-modules/constants/ecommerce';
import {
	EcommerceProduct,
	EcommerceProductVariant,
	EcommerceProductVariantQuantity,
	EcommerceVariantPrice,
	ElasticSearchVariant,
	ElasticSearchResult,
} from '@zyro-inc/site-modules/types';

export const COLUMN_COUNT_6 = 6;
export const COLUMN_COUNT_5 = 5;
export const COLUMN_COUNT_4 = 4;
export const COLUMN_COUNT_3 = 3;
export const COLUMN_COUNT_2 = 2;

export const productsPerPageByColumnCount = {
	[COLUMN_COUNT_6]: 18,
	[COLUMN_COUNT_5]: 15,
	[COLUMN_COUNT_4]: 12,
	[COLUMN_COUNT_3]: 9,
	[COLUMN_COUNT_2]: 6,
};

const isBookingProductDurationInHours = (product: EcommerceProduct) => (
	product?.variants[0].booking_event?.length_unit === BOOKING_DURATION_UNIT_HOURS
);

export function getBookingDuration(product: EcommerceProduct): number {
	return product?.variants[0].booking_event?.length || 0;
}

export const getFormattedBookingDuration = (product: EcommerceProduct, translations: Record<string, string>) => {
	// not using toPrecision because bigger numbers that have more than 3 digits are displayed with exponential notation
	const roundNumberToTwoDecimals = (number: number) => Math.round(number * 100) / 100;

	if (isBookingProductDurationInHours(product)) {
		return `${roundNumberToTwoDecimals(getBookingDuration(product) / 1000 / 60 / 60)} ${translations.hourShort}`;
	}

	return `${roundNumberToTwoDecimals(getBookingDuration(product) / 1000 / 60)} ${translations.minuteShort}`;
};

export const isProductPriceRangeShown = (product: EcommerceProduct | ElasticSearchResult): boolean => {
	if (product[SITE_PRODUCT_SELECTION] !== SITE_PRODUCT_SELECTION_TYPE_LOWEST) {
		return false;
	}

	// TODO: remove type any
	return !(product.variants as any).every(
		(item: any, _: any, arr: Array<EcommerceProductVariant | ElasticSearchVariant>) => {
			const arrayPrices = (arr[0] as any)?.prices?.[0] ?? arr[0];
			const itemsPrices = item?.prices?.[0] ?? item;

			const firstPrice = arrayPrices.sale_amount ?? arrayPrices.amount;
			const priceToCompareWith = itemsPrices.sale_amount ?? itemsPrices.amount;

			return firstPrice === priceToCompareWith;
		},
	);
};

export const getVariantQuantity = ({
	variantsQuantity,
	variantId,
}: {
	variantsQuantity: Array<EcommerceProductVariantQuantity | ElasticSearchVariant>,
	variantId: string,
}) => variantsQuantity.find(
	(variant) => variant.id === variantId,
)?.inventory_quantity || 0;

export const getFullProductQuantity = ({
	product,
	variantsQuantity,
}: {
	product: EcommerceProduct | ElasticSearchResult,
	variantsQuantity: Array<EcommerceProductVariantQuantity | ElasticSearchVariant>,
}) => (product.variants as any).reduce((acc: number, curr: EcommerceProductVariantQuantity | ElasticSearchVariant) => acc
	+ getVariantQuantity({
		variantsQuantity,
		variantId: curr.id,
	}), 0);

export const getIsProductInStock = ({
	product,
	variantsQuantity,
}: {
	product: EcommerceProduct | ElasticSearchResult,
	variantsQuantity: Array<EcommerceProductVariantQuantity | ElasticSearchVariant>,
}) => {
	if (!product.variants[0].manage_inventory) {
		return true;
	}

	const fullProductQuantity = getFullProductQuantity({
		product,
		variantsQuantity,
	});

	return fullProductQuantity > 0;
};

export const getLowestPriceVariant = ({ variants }: {variants: Array<EcommerceVariantPrice | ElasticSearchVariant>}) => variants.reduce(
	(acc, curr) => ((acc.sale_amount || acc.amount) <= (curr.sale_amount || curr.amount) ? acc : curr),
);
