import {
	ref,
	computed,
} from 'vue';

export const isSearchDropdownOpen = ref(false);
const openedSearchElementBlockId = ref(null);

export const useSearchElementDropdown = ({ blockId }) => {
	const isSearchOpenedInCurrentBlock = computed(() => openedSearchElementBlockId.value === blockId && isSearchDropdownOpen.value);

	const toggleSearchDropdown = (_isSearchDropdownOpen) => {
		openedSearchElementBlockId.value = blockId;
		isSearchDropdownOpen.value = _isSearchDropdownOpen;
	};

	return {
		isSearchOpenedInCurrentBlock,
		toggleSearchDropdown,
	};
};
